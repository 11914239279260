import React, { useState, useEffect } from 'react';
import { Panel, View, ScreenSpinner, AdaptivityProvider, AppRoot, ConfigProvider, SplitLayout, SplitCol, PanelHeader, Group, FormItem, Select, Avatar, CardScroll, Div, Card, Text, SimpleCell, InfoRow, Spacing, Header, PullToRefresh, Footer, Link, Placeholder, PanelHeaderBack } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import parse from 'node-html-parser'
import { Icon56EventOutline } from '@vkontakte/icons';

const App = () => {
	const [activePanel, setActivePanel] = useState('home');
	const [data, setData] = useState([])
	const [selected, setSelected] = useState(-1)
	const [popout, setPopout] = useState(<ScreenSpinner size='large' />);
	const [fetching, setFetching] = useState(false)

	useEffect(() => {
		async function fetchData() {
			let body = await fetch(`https://api.li0ard.rest/fuckcors?url=https://www.meteonova.ru/allergy/28698-Omsk.htm`)
			body = await body.text()
			let parsed = parse(body)
			parsed = parsed.querySelector("#frc_content_weather > section > table > tbody")
			let buff = []
			for(let i of parsed.querySelectorAll("tr")) {
				buff.push([
					i.querySelectorAll("td")[0].textContent, // Дата,
					i.querySelectorAll("td")[1].querySelector("div").getAttribute("class").replace("circle ", "").replace(" tree", ""), // Деревья
					i.querySelectorAll("td")[2].querySelector("div").getAttribute("class").replace("circle ", "").replace(" ragweed", ""), // Злаки
					i.querySelectorAll("td")[3].querySelector("div").getAttribute("class").replace("circle ", "").replace(" grass", ""), // Травы
					i.querySelectorAll("td")[4].querySelector("div").getAttribute("class").replace("circle ", "").replace(" health", ""), // Самочувствие
					i.querySelectorAll("td")[5].textContent // Описание
				])
			}
			let t;
			buff = buff.filter(( t={}, a=> !(t[a]=a in t) ))
			console.log(buff)
			setData(buff)
			setPopout(null)
		}
		fetchData();
	}, []);

	const go = e => {
		setActivePanel(e.currentTarget.dataset.to);
	};

	async function refresh() {
		setFetching(true)
		let body = await fetch(`https://api.li0ard.rest/fuckcors?url=https://www.meteonova.ru/allergy/28698-Omsk.htm`)
			body = await body.text()
			let parsed = parse(body)
			parsed = parsed.querySelector("#frc_content_weather > section > table > tbody")
			let buff = []
			for(let i of parsed.querySelectorAll("tr")) {
				buff.push([
					i.querySelectorAll("td")[0].textContent, // Дата,
					i.querySelectorAll("td")[1].querySelector("div").getAttribute("class").replace("circle ", "").replace(" tree", ""), // Деревья
					i.querySelectorAll("td")[2].querySelector("div").getAttribute("class").replace("circle ", "").replace(" ragweed", ""), // Злаки
					i.querySelectorAll("td")[3].querySelector("div").getAttribute("class").replace("circle ", "").replace(" grass", ""), // Травы
					i.querySelectorAll("td")[4].querySelector("div").getAttribute("class").replace("circle ", "").replace(" health", ""), // Самочувствие
					i.querySelectorAll("td")[5].textContent // Описание
				])
			}
			let t;
			buff = buff.filter(( t={}, a=> !(t[a]=a in t) ))
			console.log(buff)
			setData(buff)
		setFetching(false)
	}

	const renderDay = e => {
		
	}

	return (
		<ConfigProvider>
			<AdaptivityProvider>
				<AppRoot>
					<SplitLayout popout={popout}>
						<SplitCol>
							<View activePanel={activePanel}>
								<Panel id="home">
									<PullToRefresh onRefresh={refresh} isFetching={fetching}>
										<PanelHeader>Аллергия.Омск</PanelHeader>
										<Group>
											<FormItem top="День">
											<Select onChange={(e) => setSelected(parseInt(e.target.value))} id="date" placeholder='Не выбран' options={data.map((i, index) => ({
												label: i[0],
												value: index,
											}))}>
											</Select>
											</FormItem>
											{selected >=0 ? <Group mode='plain'>
												<CardScroll size="s">
													<Card>
														<Div>
															<center>
																<Avatar gradientColor={data[selected][4]}/>
																<Text>Самочувствие (Погода)</Text>
															</center>
														</Div>
													</Card>
													<Card>
														<Div>
															<center>
																<Avatar gradientColor={data[selected][1]}/>
																<Text>Деревья</Text>
															</center>
														</Div>
													</Card>
													<Card>
														<Div>
															<center>
																<Avatar gradientColor={data[selected][2]}/>
																<Text>Злаки</Text>
															</center>
														</Div>
													</Card>
													<Card>
														<Div>
															<center>
																<Avatar gradientColor={data[selected][3]}/>
																<Text>Травы</Text>
															</center>
														</Div>
													</Card>
												</CardScroll>
												<Spacing size={16} />
												<SimpleCell hasHover={false} hasActive={false} multiline>
													<InfoRow header="Сводка по дню">{data[selected][5]}</InfoRow>
												</SimpleCell>
												<Header mode='secondary'>Условные обозначения</Header>
												<SimpleCell multiline before={<Avatar size={32} gradientColor="red"/>} hasHover={false} hasActive={false}>Ожидается пыление аллергенов / Погода усугубляет аллергические реакции</SimpleCell>
												<SimpleCell multiline before={<Avatar size={32} gradientColor="yellow"/>} hasHover={false} hasActive={false}>Пыление начинается или завершается / Погода неблагоприятна для снижения концентрации пыльцы</SimpleCell>
												<SimpleCell multiline before={<Avatar size={32} gradientColor="green"/>} hasHover={false} hasActive={false}>Пыление отсутствует / Погода благоприятна для снижения концентрации пыльцы</SimpleCell>
											</Group> : null}
											{selected < 0 || selected == -1 ? <Placeholder icon={<Icon56EventOutline />}>Выберите день</Placeholder>: null}
										</Group>
									</PullToRefresh>
									<Footer><Link onClick={() => setActivePanel("otkaz")}>Отказ от ответственности</Link><br/>&copy; {new Date().getFullYear() } <Link href='https://li0ard.rest'>li0ard</Link></Footer>
								</Panel>
								<Panel id="otkaz">
									<PanelHeader before={<PanelHeaderBack onClick={() => setActivePanel("home")}></PanelHeaderBack>}>Аллергия.Омск</PanelHeader>
									<Group>
										<Div>
											<h2>Отказ от ответственности</h2>
											<p><b>Сайт "Аллергия.Омск"</b></p>
											<p>Мы делаем все возможное, чтобы предоставить вам точные и достоверные прогнозы аллергии в городе Омск. Однако, мы не можем гарантировать абсолютную точность и надежность информации, предоставляемой на нашем сайте.</p>
											<p>Прогнозы аллергии получены от поставщика информации, данные которого в свою очередь получены из различных источников данных и моделях прогнозирования, которые могут быть подвержены изменениям и неточностям. Пожалуйста, имейте в виду, что предоставленная информация служит лишь в качестве ориентира и не является окончательным решением при принятии медицинских решений.</p>
											<p>Мы не несем ответственности за любые негативные последствия, возникшие в результате использования информации с нашего сайта. Рекомендуем всегда консультироваться с квалифицированным врачом или специалистом, чтобы получить подробный и индивидуальный прогноз аллергии и соответствующее лечение.</p>
											<p><b>Поставщик информации (Метеонова)</b></p>
											<p>Используемый в прогнозе метод является оценочным. Мы не производим замеры концентрации пыльцы в атмосфере. Фазы цветения и пыления получены исключительно на базе прогноза суммы эффективных температур. Фаза определяется приблизительно, реальные концентрации зависят от множества факторов:</p>
											<ol>
												<li>Количество цветущих растений, на которые есть аллергическая реакция</li>
												<li>Количество и продолжительность заморозков и низких температур в весенний период</li>
												<li>Количество и частота осадков в период, предшествующий цветению</li>
												<li>Расположение растений: южные склоны холмов и оврагов получают больше тепла, и цветение там наступает раньше, на северных, соответственно, позже и продолжается дольше</li>
												<li>Точность модели погоды для расчета суммы эффективных температур, а также других метеопараметров, влияющих на рост и развитие растений</li>
											</ol>
										</Div>
									</Group>
								</Panel>
							</View>
						</SplitCol>
					</SplitLayout>
				</AppRoot>
			</AdaptivityProvider>
		</ConfigProvider>
	);
}

export default App;
